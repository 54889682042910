import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import img from './beam-mascot.svg';
import ClearningHouseApi from '../../store/api/clearningHouse';

const Disclaimer = ({ location }) => {
  const [disclaimer, setDisclaimer] = useState('');

  useEffect(() => {
    ClearningHouseApi.getDisclaimer().then(disclaimer => {
      typeof disclaimer === 'string' &&
        disclaimer.length !== 0 &&
        setDisclaimer(disclaimer);
    });
  }, []);

  return (
    <div>
      <section className="Section Section--leading">
        <h1>Lighten your workload. Brighten your day.</h1>
        <div className="row">
          <div className="col-sm-7">
            <p>
              You're only four simple steps from a brighter way to pay super
              from your payroll.
            </p>
            <Link
              className="Button Button--primary Button--next"
              to={{
                pathname: '/your-details',
                hash: location.hash
              }}
              style={{ display: 'inline-block', marginTop: '4rem' }}
            >
              <span>Start</span>
            </Link>
          </div>
          <div className="col-sm-5">
            <img src={img} alt="" />
          </div>
        </div>
      </section>
      <section className="Section">
        <>
          <h3>Important information</h3>
          <p>
            Beam is powered by Australian Retirement Trust, one of Australia's
            biggest and fastest growing super funds.
          </p>
          <p>
            Beam is issued by Precision Administration Services Pty Ltd
            (Precision), (ABN 47 098 977 667, AFSL No. 246604). Precision is
            wholly owned by Australian Retirement Trust Pty Ltd (ABN 88 010 720
            840, AFSL No. 228975) as Trustee for the Australian Retirement Trust
            (ABN 60 905 115 063).
          </p>
          {disclaimer !== '' && (
            <div
              style={{ marginTop: '2rem' }}
              dangerouslySetInnerHTML={{
                __html: disclaimer
              }}
            />
          )}
        </>
      </section>
    </div>
  );
};

export default Disclaimer;
