import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './History';
import AuthRoute from './AuthRoute';
import Header from './components/Header/Header';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Forbidden from './pages/Forbidden/Forbidden';
import EnableGoogleAnalytics from './utils/EnableGoogleAnalytics';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          {/* EnableGoogleAnalytics for global GTM data collection */}
          <EnableGoogleAnalytics />
          <div className="App">
            <div className="PageContainer">
              <Header />
              <Switch>
                <Route
                  exact
                  path="/forbidden"
                  render={props => <Forbidden {...props} />}
                />
                <AuthRoute path="/" />
              </Switch>
            </div>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
