import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

export function EnableGoogleAnalytics({ location }) {
  useEffect(() => {
    // disable GA4's built-in automatic tracking, run only once
    window.gtag('config', process.env.REACT_APP_GTM_ID, {
      send_page_view: false
    });
  }, []);

  useEffect(
    () => {
      /* 
    register our own page view tracking with enhanced data and integrate with react-router-dom
    by subscribing to the location property updates provided by withRouter
    */
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash
      });
    },
    [location]
  );

  return null;
}

export default withRouter(EnableGoogleAnalytics);
